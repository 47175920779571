import ParticipantSlice from './ParticipantSlice';
import ParticipantAnswerSlice from './ParticipantAnswerSlice';
import testSlice from './TestSlice';

const reducer = {
  testSlice,
  ParticipantSlice,
  ParticipantAnswerSlice,
};
export default reducer;
