import appConfig from 'config/appConfig';
import client from './index';

export const createParticipantUserEntryApi = (vendorId, browserInfo, userLandingUrl) => client().get('createParticipantUserEntry', {
  params: {
    vendorId,
    browserData: JSON.stringify(browserInfo),
    userLandingUrl: encodeURIComponent(userLandingUrl),
    versionNumber: appConfig.version,
  },
});

export const getParticipantDemoStatusV2Api = (vendorId, browserInfo, userLandingUrl) => client().get('getParticipantDemoStatusV2', {
  params: {
    vendorId,
    browserData: JSON.stringify(browserInfo),
    userLandingUrl: encodeURIComponent(userLandingUrl),
    versionNumber: appConfig.version,
  },
});
export const createParticipantV3Api = (participantData, landingUrlString, newUserId, PID, botDetectionResult) => client().get('createParticipantV3', {
  params: {
    queryParams: participantData,
    userLandingUrl: landingUrlString,
    cookieUserid: newUserId,
    PID,
    b2r: botDetectionResult && botDetectionResult.bot ? 1 : 0,
  },
});

export const participantsDemographicsApi = (participantData, landingUrlString, newUserId) => client().get('participantsDemographics', {
  params: {
    queryParams: participantData,
    userLandingUrl: landingUrlString,
    cookieUserid: newUserId,
    appType: 'zampparticipantV2',
  },
});
export const participantFailedInScreenersV2Api = (data) => client().post('participantFailedInScreenersV2', data);
export const checkScreenerForQuotaRouterV2Api = (params) => client().post('checkScreenerForQuotaRouterV2', params);
export const saveParticipantReplyV2Api = (data) => client().post('saveParticipantReplyV2', data);

export const clientRedirectUpdateApi = (data) => client().get('clientRedirectUpdate', {
  params: {
    studyId: data.studyId,
    pid: data.pid,
    redirectUrl: data.redirectUrl,
  },
});

export const getExposeClientParticipantDataApi = (data) => client().get('getExposeClientParticipantData', {
  params: data,
});

export const getCMQuestionnaireApi = (data) => client().get('chuck-miller/get-questions', {
  params: data,
});

export const saveCMResponseApi = (data) => client().post('chuck-miller/submit-response', data);
