import axios from 'axios';
import appConfig from 'config/appConfig';
// import { getAccessToken } from 'config/localStorage';
// import { userLogout } from 'actions/userAuth';

export default (headers = {}) => {
  const service = axios.create({
    baseURL: appConfig.siteURL,
    headers: {
      // Authorization: `Bearer ${getAccessToken()}`,
      ...headers,
    },
  });
  service.interceptors.response.use(
    (response) => response,
    (error) => {
      const errorResponse = error.response;
      if (errorResponse.status === 401) {
        // window.location = '/unauthorized';
      }
      throw error;
    },
  );
  return service;
};
