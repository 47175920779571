const siteURL = process.env.REACT_APP_API_URL;
// eslint-disable-next-line no-console
console.log(process.env);

const appConfig = {
  siteURL,
  version: '1.1.15',
};

export default appConfig;
